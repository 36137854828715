import React from 'react';

import AppRoutes from './routes'
import './App.scss';

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
